import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import { OutboundLink } from '../../components/UI/OutboundLink/OutboundLink';
import Layout from '../../layouts/layout';
import { report_app_store_click, URLS } from '../../shared/utils';

import * as classes from './aida-xerox-app.module.scss';

const AidaXeroxAppPage = props => {
    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="aida-xerox-app-page:pageTitle">Xerox® ConnectKey® Application</Trans>}
            subtitle={<Trans i18nKey="aida-xerox-app-page:pageSubtitle">Empower your Xerox® device with Artificial
                                                                        Intelligence</Trans>}
            cover="aida-xerox-app-gallery.jpg"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="aida-xerox-app-page:pageDescription">
                <p>Unlock the power of your documents with AIDA, the <strong>one-click solution</strong> for document
                   automation on Xerox® ConnectKey® devices.</p>
                <p>Utilizing Artificial Intelligence and Machine Learning, AIDA not only digitizes your documents but
                   also <strong>extracts key information</strong> like dates, addresses, and barcodes.</p>
                <p>Streamline your workflow, send data to various platforms, and evolve AIDA into your personal or
                   business assistant.</p>
            </Trans>
            <p>
                <Trans i18nKey="aida-xerox-app-page:download">
                    Download AIDA ConnectKey® App on the&nbsp;
                    <OutboundLink
                        href={URLS.APP_GALLERY_AIDA}
                        onClick={() => report_app_store_click('Xerox App Gallery')}
                        target="_blank" rel="noopener">Xerox<sup>&reg;</sup> App
                                                       Gallery
                    </OutboundLink>
                </Trans>.
            </p>
        </div>
        <div className={clsx(classes.FeaturesContainer, 'container p-0')}>
            <div className={classes.Features}>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/xerox-app-gallery/aida-xerox-app-gallery-01-simplicity-security.png"
                            alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <h2><Trans>Simplicity and security</Trans></h2>
                            <Trans i18nKey="aida-xerox-app-page:feature1">
                                <p>Safe and easy matching of the Xerox®
                                   ConnectKey® device to the AIDA account with
                                   QR code, thus eliminating the hassle of
                                   entering a username and / or password every
                                   time.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <h2><Trans>1-touch speed</Trans></h2>
                            <Trans i18nKey="aida-xerox-app-page:feature2">
                                <p>A single tap to load documents into AIDA reduces user
                                   time and the chance of error.</p><p>Split &amp; batch
                                                                       functionality also
                                                                       allows you to
                                                                       automatically load
                                                                       and split an entire
                                                                       batch of documents
                                                                       using the maximum
                                                                       capacity of the
                                                                       automatic document
                                                                       feeder.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage src="../../images/xerox-app-gallery/aida-xerox-app-gallery-02-1-touch-speed.png"
                                     alt="AIDA" />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage
                            src="../../images/xerox-app-gallery/aida-xerox-app-gallery-03-lookup-touchscreen.png"
                            alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <h2><Trans>Lookup on the touchscreen</Trans></h2>
                            <Trans i18nKey="aida-xerox-app-page:feature3">
                                <p>You can input relevant document
                                   information directly from the Xerox®
                                   ConnectKey® device display via the safety
                                   of lookup, with filters and searches on the
                                   data manually imported or automatically
                                   learned from AIDA.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-1 order-md-0', classes.Description)}>
                        <div>
                            <h2><Trans>Registration</Trans></h2>
                            <Trans i18nKey="aida-xerox-app-page:feature4">
                                <p>Many times the information to identify a document is
                                                                not printed directly on it.</p><p>AIDA recognizes
                                                                                                  one-dimensional
                                                                                                  barcodes, QR codes or
                                                                                                  other types based on
                                                                                                  international
                                                                                                  standards, and
                                                                                                  automatically links
                                                                                                  missing information to
                                                                                                  the document.</p>
                            </Trans>
                        </div>
                    </div>
                    <div className={clsx('col-md-6 order-0 order-md-1', classes.Picture)}>
                        <StaticImage
                            src="../../images/xerox-app-gallery/aida-xerox-app-gallery-04-registration-barcode-qrcode.png"
                            alt="AIDA" />
                    </div>
                </div>
                <div className={clsx('row', classes.Row)}>
                    <div className={clsx('col-md-6 order-0', classes.Picture)}>
                        <StaticImage src="../../images/xerox-app-gallery/aida-xerox-app-gallery-05-distribution.png"
                                     alt="AIDA" />
                    </div>
                    <div className={clsx('col-md-6 order-1', classes.Description)}>
                        <div>
                            <h2><Trans>Distribution</Trans></h2>
                            <Trans i18nKey="aida-xerox-app-page:feature5">
                                <p>Any operation you do on your AIDA account, such as
                                                                configuring a new document type with its property set,
                                                                is immediately visible to all Xerox® ConnectKey® devices
                                                                linked, anywhere in the world.</p>
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default AidaXeroxAppPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "aida-xerox-app-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'aida-xerox-app-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Platform - Xerox App Gallery App';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
